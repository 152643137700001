.sf-menu {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none
}

.sf-menu .sf-mega {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99
}

.sf-menu > li {
    float: left
}

.sf-menu li:hover > .sf-mega,
.sf-menu li.sfHover > .sf-mega {
    display: block
}

.sf-menu > li > a {
    display: block;
    position: relative
}

.sf-menu {
    float: left;
    margin-bottom: 1em;
    width: 100%
}

.sf-menu .sf-mega {
    box-shadow: 2px 3px 6px rgba(0, 0, 0, .2);
    width: 100%
}

.sf-menu > li > a {
    border-left: 1px solid #fff;
    border-top: 1px solid #dfeeff;
    border-top: 1px solid rgba(255, 255, 255, .5);
    padding: .75em 1em;
    text-decoration: none;
    zoom: 1;
    color: #13a
}

.sf-menu > li {
    background: #bdd2ff;
    -webkit-transition: background .2s;
    transition: background .2s
}

.sf-menu > li:hover,
.sf-menu > li.sfHover {
    background: #cfdeff;
    -webkit-transition: none;
    transition: none
}

.sf-mega {
    background-color: #cfdeff;
    padding: 1em;
    box-sizing: border-box;
    width: 100%
}

.sf-mega-section {
    float: left;
    width: 8em;
    padding: 0 1em 1em 0;
    margin-right: 1em;
    border-right: 1px solid #b4c8f5
}

.sf-arrows .sf-with-ul {
    padding-right: 2.5em;
    *padding-right: 1em
}

.sf-arrows .sf-with-ul:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -3px;
    height: 0;
    width: 0;
    border: 5px solid transparent;
    border-top-color: #dfeeff;
    border-top-color: rgba(255, 255, 255, .5)
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
    border-top-color: white
}
